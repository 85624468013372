@font-face {
  font-family: "Nunito";
  src: url(./assets/font/Nunito-VariableFont_wght.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, Nunito, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 500px;
  max-width: 90vw;
  margin: auto;;
}
