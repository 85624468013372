.question-wrapper {
  border: 1px solid #cfcfcf;
  padding: 1em;
  margin: 1em 0;

  p {
    margin: .25em 0;
    img {
      max-width: 100%;
    }
  }
  
}

.question-title {
  label {
    display: block;
    margin-bottom: 1em;
  }
}

.button-row {
  display: flex;
  justify-content: space-between;
  padding: 1em 0;
  box-sizing: border-box;
}

.multi, .choose {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .option {
    width: 100%;
    overflow: hidden;
    &.no-image {
      label  {
        gap: 1em;
        display: flex;
        align-items: center;
        input {
          margin: 0 .25em;
        }
      }
    }
    &.with-image {
      width: 50%;
      label {
        margin: .5em;
        padding: .5em;
        display: block;
        position: relative;
        border: 1px solid lightgrey;
      }
      &.selected {
        label {
          border-color: darkgrey;
        }
      }

      input {
        position: absolute;
        top: 1em;
        right: 1em;
      }
    }

    img {
      max-width: 100%;
      border-radius: 10px;
    }
  }
}

.spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.spinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.spinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.spinner div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  opacity: .5;
  display: flex;
  align-items: center;
  justify-content: center;
}