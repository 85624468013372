.table {
  width: 100vw;
  border-collapse: collapse;
  border: 1px solid black;
  position: absolute;
  left: 0;
  margin: 1em 0;

  th, td {
    border: 1px solid black;
  }
}